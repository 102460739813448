import { FaWhatsappSquare } from "react-icons/fa";

import "./styles.css";

const WhatsAppButton = () => (
  <a
    href="https://wa.me/5521974749484?text=Ol%C3%A1,%20acessei%20seu%20Site%20e%20gostaria%20de%20saber%20sobre%20Aluguel%20de%20Limousine"
    target="_blank"
    rel="noopener noreferrer"
    className="fale-conosco"
  >
    FALE CONOSCO!
    <FaWhatsappSquare size={50} color="#EEEEEE" />
  </a>
);

export default WhatsAppButton;
