import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
} from "react-icons/fa";
import limousine1 from "../../assets/limousine1.jpeg";
import limousine2 from "../../assets/limousine2.jpeg";
import limousine3 from "../../assets/limousine3.jpeg";

import "./styles.css";

const Contatos = () => (
  <div className="contatos-container">
    <div className="left-side">
      <span className="title">CONTATOS</span>
      <div className="footer-container">
        <div className="footer-item">
          <a href="tel:+5521974749484" className="contato-item">
            <span className="icon">
              <FaPhoneAlt size={30} color="#000000" />
            </span>
            <span>
              (21) 97474-9484
              <br />
              (21) 96436-1883
            </span>
          </a>
          <a href="mailto:limopartyrj@gmail.com" className="contato-item">
            <span className="icon">
              <FaEnvelope size={30} color="#000000" />
            </span>
            <span>limopartyrj@gmail.com</span>
          </a>
        </div>
        <div className="footer-item">
          <a
            href="https://www.instagram.com/limopartyrio/"
            target="_blank"
            rel="noopener noreferrer"
            className="contato-item"
          >
            <span className="icon">
              <FaInstagram size={40} color="#000000" />
            </span>
            <span>Instagram</span>
          </a>
          <a
            href="https://www.facebook.com/LIMOPARTYRIO/"
            target="_blank"
            rel="noopener noreferrer"
            className="contato-item"
          >
            <span className="icon">
              <FaFacebookF size={30} color="#000000" />
            </span>
            <span>Facebook</span>
          </a>
        </div>
      </div>
    </div>
    <div className="right-side">
      <div className="first-row desktop">
        <div className="container-img first">
          <div className="border-left" />
          <img src={limousine1} alt="Limousine" className="img-limousine" />
        </div>
        <div className="container-img second">
          <img src={limousine2} alt="Limousine" className="img-limousine" />
          <div className="border-right" />
        </div>
      </div>
      <div className="container-img third desktop">
        <img src={limousine3} alt="Limousine" className="img-limousine" />
        <div className="border-right" />
      </div>
    </div>
    <div className="mobile">
      <div className="container-img">
        <div className="border-left" />
        <img src={limousine1} alt="Limousine" className="img-limousine" />
      </div>
      <div className="right-container-mobile">
        <div className="container-img">
          <img src={limousine2} alt="Limousine" className="img-limousine" />
          <div className="border-right" />
        </div>
        <div className="container-img">
          <img src={limousine3} alt="Limousine" className="img-limousine" />
          <div className="border-right" />
        </div>
      </div>
    </div>
  </div>
);

export default Contatos;
