import Carousel from "react-multi-carousel";
import {
  CustomLeftArrow,
  CustomRightArrow,
  responsive,
} from "../../components/CustomArrow";
import imgEventoInfantil from "../../assets/evento-infantil.jpeg";
import imgEventoAdolescente from "../../assets/evento-adolescente.jpeg";
import imgEventoAdulto from "../../assets/evento-adulto.jpeg";
import imgCasamento from "../../assets/casamento.jpeg";
import imgDebutante from "../../assets/debutante.jpeg";

import "./styles.css";

const CarouselItemEvento = ({ img, title, description, onClick, files }) => {
  const backgroundImgDrive = (files || []).find(
    (it) => it.name.toLowerCase().indexOf("principal") >= 0
  );

  const openModal = () => {
    if (files.length) {
      onClick({ title, description, files });
    }
  };

  return (
    <div
      onClick={openModal}
      {...(files.length && { style: { cursor: "pointer" } })}
    >
      <div className="img-container">
        <div className="img-border" />
        <div
          style={{
            background: `url(${
              backgroundImgDrive
                ? backgroundImgDrive.thumbnailLink.replace("=s220", "")
                : img
            })`,
          }}
          className="img"
        />
        <div className="carousel-group">
          <span className="carousel-title">{title}</span>
          <span className="carousel-subtitle">{description}</span>
        </div>
      </div>
      <div className="carousel-group mobile">
        <span className="carousel-title">{title}</span>
        <span className="carousel-subtitle">{description}</span>
      </div>
    </div>
  );
};

const Eventos = ({ openModal, images }) => (
  <div className="eventos-container">
    <Carousel
      responsive={responsive}
      infinite
      containerClass="carousel-container"
      itemClass="carousel-item"
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      autoPlay={true}
      autoPlaySpeed={5000}
    >
      <CarouselItemEvento
        img={imgEventoInfantil}
        title="EVENTO INFANTIL"
        description="Realize um sonho!"
        onClick={openModal}
        files={images.length ? images[0] : []}
      />
      <CarouselItemEvento
        img={imgEventoAdolescente}
        title="EVENTO ADOLESCENTE"
        description="Viva momentos inesquecíveis!"
        onClick={openModal}
        files={images.length ? images[1] : []}
      />
      <CarouselItemEvento
        img={imgEventoAdulto}
        title="EVENTO ADULTO"
        description="Sofisticação e elegância!"
        onClick={openModal}
        files={images.length ? images[2] : []}
      />
      <CarouselItemEvento
        img={imgCasamento}
        title="CASAMENTO"
        description="Seu momento ainda mais especial!"
        onClick={openModal}
        files={images.length ? images[3] : []}
      />
      <CarouselItemEvento
        img={imgDebutante}
        title="DEBUTANTE"
        description="15 anos com mais estilo!"
        onClick={openModal}
        files={images.length ? images[4] : []}
      />
    </Carousel>
  </div>
);

export default Eventos;
