import Carousel from "react-multi-carousel";
import {
  CustomLeftArrow,
  CustomRightArrow,
  responsive,
} from "../../components/CustomArrow";
import WhatsAppButton from "../../components/WhatsAppButton";
import { FaStar } from "react-icons/fa";

import "./styles.css";

const questions = [
  {
    name: "Glicia Carnevale",
    text: (
      <>
        Uma experiência fantástica! Niver da minha afilhada de 6 anos, surpresa!
        Agendei de um dia para outro. Super rápido e atencioso do atendimento
        inicial ao motorista Felipe, que chegou de gravata rosa, bem vestido.
        Nos incentivou a tirar fotos, tirou do grupo pra gente, fez as paradas
        na Lagoa e passeio panorâmico na orla. Segurança total. Só recomendo
        muito!!!
        <br />
        Fiquei encantada e agora sou a melhor dinda do mundo para minha pequena!
      </>
    ),
  },
  {
    name: "Maria Travassos",
    text: "Foi um dia incrível Minha filha Ana Markelly e seus amigos curtiram muito festa    e agradecemos por esse trabalho excelência parabéns toda equipe especial Motorista Felipe me muito obrigada",
  },
  {
    name: "Fabiana Paiva",
    text: (
      <>
        Gostaria de parabenizar o serviço de vcs no evento da minha filha no dia 18/05.
        Foi tudo maravilhoso!!
        <br />
        Foi uma experiência única e muito divertida das meninas no passeio na limousine!!!
        <br />
        O motorista sempre muito simpático, solícito e agradável.
        Foi uma tarde muito divertida!!!
      </>
    ),
  },
  {
    name: "Roselane Apolinario",
    text: (
      <>
        Bom dia!!
        <br />
        Quero agradecer toda atenção e comprometimento com a minha pessoa. Dizer q amei tudo do início ao fim. O motorista super mega simpático, a Marcela fotógrafa foi incrível e o dia foi mais q especial e ficará marcado na memória para sempre, minha filha está radiante até agora 😅 MUITO OBRIGADA, super mega RECOMENDO
      </>
    ),
  },
  {
    name: "Danielle Oliveira Da Fonseca Pontes",
    text: (
      <>
        Não podia deixar de passar aqui para demonstrar como dia de ontem foi maravilhoso e vcs fizeram o dia da minha princesa mais especial.
        <br />
        Fiz o orçamento com 4 empresas, mas desde o primeiro contato a limo party me passou confiança e tranquilidade sempre disponível para tirar qualquer dúvida.
        <br />
        Thatiana seu atendimento fez com q de todas eu escolhesse vcs, pois foi atenciosa em todas as vezes q eu fiz contato( e olha q foram inúmeras) e o Felipe q pessoa maravilhosa conduziu o passeio com muita segurança e responsabilidade, ajudou muita coisa e fez com q evento fosse perfeito do inicio ao fim.
        <br />
        Sem palavras para agradecer. 
        <br />
        Super indico.❤❤
      </>
    ),
  },
  {
    name: "Nelson Junior",
    text: (
      <>
        Obrigado pelos serviços de excelência que foi prestado. Minha filha ficou extremamente feliz e satisfeita. Super recomendo. Equipe fantástica!
      </>
    ),
  },
  {
    name: "Soraia Alves",
    text: (
      <>
        Simplesmente maravilhoso! Motorista mto simpático, aliás, a Tatiana que falei desde o primeiro contato tbm mto atenciosa, enfim, recomendo, pois minha filha e todos os convidados ficaram maravilhados. Muito obrigada por nos proporcionar um momento lindo!
      </>
    ),
  },
  {
    name: "Marília Maciel",
    text: (
      <>
        Amamos tudo!! Minha filha e as amigas ficaram muito felizes com o passeio. Se sentiram como princesas.
        <br />
        Motorista simpático, legal e prestativo. A fotógrafa Marcella tb foi muito legal,simpática e prestativa.
        <br />
        Parabéns pela equipe e pelo trabalho.
        <br />
        Amamos!!
      </>
    ),
  },
  {
    name: "Karina Medeiros",
    text: (
      <>
        A festa foi um sucesso, só tenho elogios a sua equipe e seus profissionais que nos proporcionaram um evento maravilhoso. Tudo estava de altíssima qualidade. Parabéns pelo serviço prestado e continue assim! Agradeço novamente por tudo
        <br />
        Gratidão minha filha está eufórica até agora ❤️
      </>
    ),
  },
  {
    name: "Miriam Galvão",
    text: (
      <>
       Recomendo a LimoParty para seu evento infantil, juvenil, seja qual for a faixa etária. O Felipe é bem atencioso, tira todas as dúvidas é bem tranquilo e prestativo. Limosine linda, limpa, refrigerada e bem abastecida tanto na ida quanto na volta do evento. Superou a nossa expectativa.
      </>
    ),
  },
  {
    name: "Estela Carvalho",
    text: (
      <>
       Atendentes são super profissionais, educados, atenciosos. Fazem de tudo para seu evento ser um sucesso. Minhas filhas e seus amigos amaram!! Profissionalismo, atenção, pontualidade. Super recomendo ❤️👍
      </>
    ),
  },
  {
    name: "Patricia da Silva Marmelo",
    text: (
      <>
       Hoje foi comemorado o niver da minha mãe e estou feliz em ver a felicidade dela. Os funcionários são sensacionais. Foi tudo perfeito desdo primeiro contato com a funcionaria Tatiana, que foi super educada e solicita. E hoje para fechar com chave de ouro o motorista Felipe é incrível, atencioso demais e de uma cordialidade admirável. Parabéns a empresa por terem colaboradores tão marcantes!
      </>
    ),
  },
  {
    name: "Mara Rubia Lira",
    text: (
      <>
        Foi tudo maravilhoso! Vcs entendem que trabalham com sonhos e são muito comprometidos para que tudo saia perfeito.
        <br />
        Parabéns pela pontualidade, pela gentileza do motorista, pela qualidade dos produtos servidos, pela decoração, enfim, depositei as minhas expectativas nesta empresa e fiquei muito satisfeita.
      </>
    ),
  },
  {
    name: "Sabrina Carvalho Martins",
    text: (
      <>
        Excelente profissional, Educado, respeitador, cumpriram tudo que estava no contrato e todas as medidas para nos proteger 🙏 foi um dia muito maravilhoso, minha filha amou e as amiguinhas dela também, Jajá vou marcar eu e minhas amigas 🤭😍 parabéns pelo trabalho.
      </>
    ),
  },
  {
    name: "Rafaelle Montenegro",
    text: (
      <>
        Excelente atendimento e atenção desde o primeiro atendimento, até o motorista que foi super atencioso, ajudou a resolver itinerários, mudanças de horários e os imprevistos que não dependiam nem de nós e nem da empresa! Bebidas, decoração, brindes e guloseimas de primeira qualidade!! Somente agradecer pelo serviço nota 1.000!!! 😍😘😁
      </>
    ),
  },
  {
    name: "Aline Menezes",
    text: (
      <>
        Super recomendo a Limo Party. Minha filha e suas amigas ficaram encantadas com a limousine, que realmente é linda. A equipe é super atenciosa e atenderam todos meus pedidos. Foi um prazer comemorar o aniversário da minha filha com vocês.
      </>
    ),
  },
];

const Avaliacoes = () => (
  <div className="avaliacoes-container">
    <div className="container-title">
      <span className="title">VEJA O QUE OS NOSSOS CLIENTES DIZEM</span>
      <div className="line" />
    </div>
    <Carousel
      responsive={responsive}
      itemClass="carousel-item"
      infinite
      customLeftArrow={<CustomLeftArrow color="#EEEEEE" />}
      customRightArrow={<CustomRightArrow color="#EEEEEE" />}
      autoPlay={true}
      autoPlaySpeed={15000}
    >
      {questions.map((it) => (
        <div className="carousel-container" key={it.name}>
          <div className="title-container">
            <span className="title">{it.name}</span>
            <div className="stars-container">
              <FaStar color="#EDDD4C" size={22} />
              <FaStar color="#EDDD4C" size={22} />
              <FaStar color="#EDDD4C" size={22} />
              <FaStar color="#EDDD4C" size={22} />
              <FaStar color="#EDDD4C" size={22} />
            </div>
          </div>
          <div className="text">{it.text}</div>
        </div>
      ))}
    </Carousel>
    <div className="fale-conosco-container">
      <WhatsAppButton />
    </div>
  </div>
);

export default Avaliacoes;
