import Carousel from "react-multi-carousel";
import {
  CustomLeftArrow,
  CustomRightArrow,
  responsive,
} from "../../components/CustomArrow";
import WhatsAppButton from "../../components/WhatsAppButton";
import limousineIcon from "../../assets/limousine.png";

import "./styles.css";

const questions = [
  {
    title: "Quem é a Limo Party?",
    text: "Somos uma empresa comprometida em realizar sonhos! No mercado desde 2018, já realizamos mais de 3000 eventos. Transformando sonhos em experiências inesquecíveis! Trazemos sofisticação ao seu evento e transformando-o em um sucesso! Nossa frota possui 3 veículos ( DUAS LIMOUSINES ROSA E UMA LIMOUSINE BRANCA). Atendemos toda a cidade do Rio de Janeiro e alguns municípios próximos. Nossos profissionais são especializados e treinados em proporcionar o melhor para os nossos clientes. Os nossos motoristas trabalham sempre uniformizados (traje esporte fino) e possuem anos de experiências.",
  },
  {
    title: "Como são as nossas Limousines?",
    text: "Nossas Limousines possuem teto solar, telas de LCD, iluminação em LED e som. UM VERDADEIRO LUXO! As Limousines rosas possuem CÍLIOS. Puro charme! Inovamos o mercado trazendo a LUZ NEGRA para as nossas Limusines, agora a sua festa neon pode ser comemorada com ainda mais estilo!",
  },
  {
    title: "Para quais tipos de eventos posso alugar uma Limousine?",
    text: "Na Limo Party oferecemos Limousine para diversos tipos de eventos, como festas para crianças, adolescentes e adultos, festas de 15 anos, casamentos, despedidas de solteiro, tours pelo Rio de Janeiro, encontro com amigos, eventos corporativos, transfer de aeroporto e muito mais. Venha celebrar conosco!",
  },
  {
    title: "Qual trajeto poderá ser feito durante o passeio?",
    text: "Nós sugerimos vários trajetos e pontos de paradas, todos pontos sugeridos são locais onde conseguimos estacionar a Limousine para que todos embarque e desembarque com segurança. O trajeto é definido com base no local de início e de término e a quantidade de horas contratadas. Não temos limite de quilometragem! Entre os pontos mais procurados destacamos o Aterro do Flamengo, Lagoa Rodrigo de Freitas, Copacabana, Leme, Urca, Barra da Tijuca, Recreio, Praia de São Francisco, Museu Mac entre outros. Entre em contato conosco que montaremos um trajeto fantástico!",
  },
  {
    title: "Quantas pessoas cabem na Limousine?",
    text: "Venha curtir na maior Limousine do Rio de Janeiro! Pensando no conforto dos nossos clientes atendemos até 17 pessoas em eventos infantis (até 11 anos), 15 pessoas em evento com adolescente ( de 12 a 17 anos) e 14 pessoas em eventos adultos.",
  },
  {
    title: "O que está incluído no aluguel?",
    text: "Disponibilizamos um kit cortesia para os nossos clientes, tornando esse momento ainda mais especial. O kit cortesia inclui bebidas, biscoitos e guloseimas, que ficarão disponíveis no bar da Limousine para o consumo e diversão de todos! A Limousine vai ornamentada com bolas e taças de espumante. Possuímos uma mesa em madeira, espátula para a hora do parabéns, guardanapo e copos descartáveis.",
  },
  {
    title: "Quanto custa o valor da Limousine?",
    text: "Alugamos a Limousine por quantidade de horas que ela ficará a sua disposição. ESTAMOS COM VALORES PROMOCIONAIS! Entre em contato com um dos nossos consultores que teremos o prazer em te passar a tabela com os valores e explicar todo o nosso serviço.",
  },
];

const SobreNos = ({ saibaMais }) => (
  <div className="sobre-nos-container">
    <span className="sobre-nos-text">SOBRE NÓS</span>
    <div className="card">
      <span className="sobre-nos-text-mobile">SOBRE NÓS</span>
      <Carousel
        responsive={responsive}
        itemClass="carousel-item"
        infinite
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        autoPlay={true}
        autoPlaySpeed={30000}
      >
        {questions.map((it) => (
          <div className="carousel-container" key={it.title}>
            <div className="head">
              <img src={limousineIcon} alt="Limousine" />
              <span>{it.title}</span>
              <img src={limousineIcon} alt="Limousine" />
            </div>
            <div className="body">
              <span>{it.text}</span>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="footer">
        <WhatsAppButton />
      </div>
      {saibaMais}
    </div>
  </div>
);

export default SobreNos;
