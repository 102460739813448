import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import "./styles.css";

const CustomLeftArrow = ({ onClick, color = undefined }) => (
  <FaChevronLeft
    size={60}
    onClick={onClick}
    className="custom-arrow custom-left-arrow"
    {...(color && { color })}
  />
);

const CustomRightArrow = ({ onClick, color = undefined }) => (
  <FaChevronRight
    size={60}
    onClick={onClick}
    className="custom-arrow custom-right-arrow"
    {...(color && { color })}
  />
);

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export { CustomLeftArrow, CustomRightArrow, responsive };
