import { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import ReactPageScroller from "react-page-scroller";
import { FaAngleDown } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import Home from "./pages/Home";
import Avaliacoes from "./pages/Avaliacoes";
import SobreNos from "./pages/SobreNos";
import Contatos from "./pages/Contatos";
import imgLimousineBG from "./assets/limousine-bg.jpeg";
import SectionEventos from "./components/SectionEventos";

const INFANTIL_FOLDER_ID = "1VKmDUXpFnLD4YKrZEwKgGcp6paW9PqEW";
const ADOLESCENTE_FOLDER_ID = "18bCg6bc1OQhrDYjUwO47TsaxLWVFk1G_";
const ADULTO_FOLDER_ID = "1OijIdWLOs863o8fAwcx1E_3rxvXjIuMR";
const CASAMENTO_FOLDER_ID = "1FhLeMqmTNNwfOVP0_9PyzWb_C6_QHrpL";
const DEBUTANTE_FOLDER_ID = "1nF7_4ACMs7GWqxbUlGZw6tK1vUbvTsEX";

const App = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [images, setImages] = useState([]);
  const [blockScroll, setBlockScroll] = useState(false);

  useEffect(() => {
    Promise.all([
      getImagesDrive(INFANTIL_FOLDER_ID),
      getImagesDrive(ADOLESCENTE_FOLDER_ID),
      getImagesDrive(ADULTO_FOLDER_ID),
      getImagesDrive(CASAMENTO_FOLDER_ID),
      getImagesDrive(DEBUTANTE_FOLDER_ID),
    ]).then(setImages);

    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)
      );
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const getImagesDrive = (folderId) =>
    new Promise((resolve) => {
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            apiKey: process.env.REACT_APP_API_KEY,
            clientId: process.env.REACT_APP_CLIENT_ID,
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
            ],
            scope: "https://www.googleapis.com/auth/drive.readonly",
          })
          .then(() => {
            return gapi.client.drive.files.list({
              q: `'${folderId}' in parents and (mimeType contains 'image/') and mimeType != 'image/x-photoshop'`,
              fields:
                "files(id, name, mimeType, webContentLink, webViewLink, thumbnailLink)",
            });
          })
          .then((response) => {
            resolve(response.result.files);
          })
          .catch((error) => {
            console.error("Error loading Google Drive files:", error);
          });
      });
    });

  const handlePageChange = (number) => {
    if (isMobile) {
      let id = "";
      switch (number) {
        case 1:
          id = "eventos";
          break;
        case 2:
          id = "avaliacoes";
          break;
        case 3:
          id = "sobre-nos";
          break;
        case 4:
          id = "contatos";
          break;
        default:
          break;
      }
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    } else {
      setCurrentPage(number);
    }
  };

  const SaibaMais = ({ number, isBlack = false, style = {} }) => (
    <div
      className={`saiba-mais${isBlack ? " black" : ""}`}
      onClick={() => handlePageChange(number)}
      style={style}
    >
      Saiba mais
      <FaAngleDown size={30} />
    </div>
  );

  const SectionHome = () => {
    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const goToPage = (number) => {
      setOpenMobileMenu(false);
      handlePageChange(number);
    };

    return (
      <section id="home">
        <FiMenu
          color="#E20486"
          size={40}
          className="icon-menu"
          onClick={() => setOpenMobileMenu(!openMobileMenu)}
        />
        <div className={`menu${openMobileMenu ? "" : " hidden"}`}>
          <span onClick={() => goToPage(1)}>EVENTOS</span>
          <span onClick={() => goToPage(2)}>AVALIAÇÕES</span>
          <span onClick={() => goToPage(3)}>SOBRE NÓS</span>
          <span onClick={() => goToPage(4)}>CONTATOS</span>
        </div>
        <Home />
        <SaibaMais number={1} />
      </section>
    );
  };

  const SectionAvaliacoes = () => (
    <section id="avaliacoes" style={{ background: `url(${imgLimousineBG})` }}>
      <Avaliacoes />
      <SaibaMais number={3} />
    </section>
  );

  const SectionSobreNos = () => (
    <section id="sobre-nos">
      <SobreNos
        saibaMais={
          <SaibaMais number={4} isBlack={true} style={{ marginBottom: 0 }} />
        }
      />
    </section>
  );

  const SectionContatos = () => (
    <section id="contatos">
      <Contatos />
    </section>
  );

  return isMobile ? (
    <main>
      <SectionHome />
      <SectionEventos
        images={images}
        saibaMais={<SaibaMais number={2} isBlack={true} />}
        setBlockScroll={setBlockScroll}
      />
      <SectionAvaliacoes />
      <SectionSobreNos />
      <SectionContatos />
    </main>
  ) : (
    <ReactPageScroller
      animationTimer={700}
      pageOnChange={handlePageChange}
      customPageNumber={currentPage}
      animationTimerBuffer={0}
      blockScrollDown={blockScroll}
      blockScrollUp={blockScroll}
    >
      <SectionHome />
      <SectionEventos
        images={images}
        saibaMais={<SaibaMais number={2} isBlack={true} />}
        setBlockScroll={setBlockScroll}
      />
      <SectionAvaliacoes />
      <SectionSobreNos />
      <SectionContatos />
    </ReactPageScroller>
  );
};

export default App;
