import { useState } from "react";
import Eventos from "../../pages/Eventos";
import { FaTimes } from "react-icons/fa";

const SectionEventos = ({ images, saibaMais, setBlockScroll }) => {
  const [modalOpen, setModalOpen] = useState(null);

  const openModal = (data) => {
    setModalOpen(data);
    setBlockScroll(true);
  };

  const closeModal = () => {
    setModalOpen(null);
    setBlockScroll(false);
  };

  return (
    <section id="eventos">
      <div className={`modal${modalOpen ? " active" : ""}`}>
        <div className="overlay" onClick={closeModal} />
        <div className="modal-content">
          {modalOpen && (
            <>
              <div className="header">
                <div>
                  <div className="title">{modalOpen.title}</div>
                  <div className="description">{modalOpen.description}</div>
                </div>
                <div className="close" onClick={closeModal}>
                  <FaTimes size={30} color="#ffffff" />
                </div>
              </div>
              <div className="img-container">
                {modalOpen.files.map((file) => (
                  <div key={file.id}>
                    <img src={file.thumbnailLink} alt={file.name} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <Eventos openModal={openModal} images={images} />
      {saibaMais}
    </section>
  );
};

export default SectionEventos;
