import { useEffect, useRef, useState } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import logo from "../../assets/logo.png";
import video from "../../assets/video_promo_geral.mp4";
import WhatsAppButton from "../../components/WhatsAppButton";

import "./styles.css";

const Video = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = isMuted;
      video.play().catch((error) => {
        console.log("Autoplay failed:", error);
      });
    }
  }, [isMuted]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="container-video">
      <div className="first-border" />
      <div className="second-border" />
      <div className="third-border" />
      <video ref={videoRef} loop playsInline preload="auto">
        <source src={video} type="video/mp4" />
        Your browser does not support video.
      </video>
      <button className="mute-button" onClick={toggleMute}>
        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
      </button>
    </div>
  );
};

const Home = () => (
  <>
    <div className="home-container">
      <div className="left-container">
        <img className="logo" src={logo} alt="Logo limo party" />
        <Video />
        <p className="first-text">
          Faça sua festa na maior limousine do Rio de Janeiro e tenha uma
          experiência inesquecível.
        </p>
        <p className="second-text">
          Nossa missão é proporcionar alegria do início ao fim do passeio.
        </p>
        <WhatsAppButton />
      </div>
      <div className="right-container">
        <Video />
      </div>
    </div>
  </>
);

export default Home;
